import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = [ "sidebar" ]
  
  connect() {
  }

  open() {
    this.sidebarTarget.show();
  }
  
  close() {
    this.sidebarTarget.close();
  }
}
